<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    :src="barImage"
    mobile-breakpoint="960"
    app
    width="260"
    v-bind="$attrs"
  >
    <template v-slot:img="props">
      <v-img :gradient="`to bottom, ${barColor}`" v-bind="props" />
    </template>

    <v-divider class="mb-1" />

    <v-list dense nav>
      <v-list-item>
        <v-list-item-avatar class="align-self-center" color="white" contain>
          <v-img src="@/assets/logo.png" max-height="30" />
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="display-1" v-text="profile.title" />
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider class="mb-2" />

    <v-list expand nav>
      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item">
          <!--  -->
        </base-item-group>

        <base-item v-else :key="`item-${i}`" :item="item" />
      </template>

      <!-- Style cascading bug  -->
      <!-- https://github.com/vuetifyjs/vuetify/pull/8574 -->
      <div />
    </v-list>

    <v-btn text color="primary" v-on:click="logout">
      <span>Signout</span>
      <v-icon right>mdi-logout</v-icon>
    </v-btn>
  </v-navigation-drawer>
</template>

<script>
import firebase from "firebase";
// Utilities
import { mapState } from "vuex";
export default {
  name: "DashboardCoreDrawer",
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    items: [
      //Dashboard
      {
        id: 0,
        icon: "mdi-view-dashboard",
        title: "Tableau de bord",
        to: "/",
      },

      /*

     
      {
        icon: "mdi-account",
        title: "user",
        to: "/pages/user",
      },
      {
        title: "rtables",
        icon: "mdi-clipboard-outline",
        to: "/tables/regular-tables",
      },
      {
        title: "typography",
        icon: "mdi-format-font",
        to: "/components/typography",
      },
      {
        title: "icons",
        icon: "mdi-chart-bubble",
        to: "/components/icons",
      },
      {
        title: "google",
        icon: "mdi-map-marker",
        to: "/maps/google-maps",
      },
      {
        title: "notifications",
        icon: "mdi-bell",
        to: "/components/notifications",
      },*/
    ],
    isLoggedIn: false,
    currentUserDisplayName: null,
    currentUserEmail: null,
  }),
  computed: {
    ...mapState(["barColor", "barImage"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    computedItems() {
      return this.items.map(this.mapItem);
    },
    profile() {
      return {
        avatar: true,
        title: this.$t("avatar"),
      };
    },
  },
  created() {
    if (firebase.auth().currentUser) {
      this.isLoggedIn = true;
      this.currentUserDisplayName = firebase.auth().currentUser.displayName;
      this.currentUserEmail = firebase.auth().currentUser.email;

      firebase
        .auth()
        .currentUser.getIdTokenResult()
        .then((tokenResult) => {
          //Liste des menu pour les admin
          if (tokenResult.claims.admin) {
            this.items.push(
              {
                icon: "mdi-cogs",
                title: "Mercarue ",
                group: "/Mercarue",
                children: [
                  {
                    title: "Catalogue",
                    to: "catalogs/catalogSettings",
                  },
                  {
                    title: "Produits postés",
                    to: "Products/PostedProducts",
                  },
                  {
                    title: "Gestion des Commandes",
                    to: "orders/Orders",
                  },
                  {
                    title: "Gestion des catégories",
                    to: "catalogs/CategoriesManagement",
                  },
                  {
                    title: "Gestion des Marques",
                    to: "markManagement/MarkManagement",
                  },
                  {
                    title: "Gestion des ClassNames",
                    to: "gestionClassNames/ClassNames",
                  },
                ],
              },

              {
                icon: " mdi-account-check",
                title: "MercaAdmin ",
                group: "/MercaAdmin",
                children: [
                  {
                    title: "Gestion des utilisateurs",
                    to: "Users",
                  },
                  {
                    icon: "mdi-list-box-outline",
                    title: "Validation des PRO ",
                    to: "ListeDemande",
                  },
                  {
                    title: "Candidatures",
                    to: "Recruitments",
                  },
                  {
                    icon: "mdi-account-multiple-outline",
                    title: "Contact",
                    to: "Contacts",
                  },
                  {
                    icon: "mdi-plus",
                    title: "Gestion de la FAQ",
                    to: "AjoutFaq",
                  },
                  {
                    icon: "mdi-cog-outline",
                    title: "Paramètres Générals ",
                    to: "Parametres",
                  },
                ],
              },

              {
                icon: "mdi-cellphone",
                title: "MercaNews ",
                group: "/MercaNews",
                children: [
                  {
                    title: "Liste des flux",
                    to: "ListeDesFlux",
                  },
                  {
                    title: "Ajouter des actualités",
                    to: "AjoutDesActualites",
                  },
                ],
              }
            );
            //Liste des menu pour les editeurs
          } else if (tokenResult.claims.editor) {
            this.items.push(
              {
                icon: "mdi-cogs",
                title: "Mercarue ",
                group: "/Mercarue",
                children: [
                  {
                    title: "Catalogue",
                    to: "catalogs/catalogSettings",
                  },
                  {
                    title: "Produits postés",
                    to: "Products/PostedProducts",
                  },
                ],
              },

              {
                icon: "mdi-cellphone",
                title: "MercaNews ",
                group: "/MercaNews",
                children: [
                  {
                    title: "Liste Des Flux",
                    to: "ListeDesFlux",
                  },
                  {
                    title: "Ajouter des actualités",
                    to: "AjoutDesActualites",
                  },
                ],
              }
            );
          }
        });
    }
  },
  methods: {
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      };
    },
    logout() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.push({
            name: "login",
          });
        });
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'
#core-navigation-drawer
  .v-list-group__header.v-list-item--active:before
    opacity: .24
  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px
      +ltr()
        margin-right: 24px
        margin-left: 12px !important
      +rtl()
        margin-left: 24px
        margin-right: 12px !important
  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px
  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px
      +rtl()
        padding-right: 8px
    .v-list-group__header
      +ltr()
        padding-right: 0
      +rtl()
        padding-right: 0
      .v-list-item__icon--text
        margin-top: 19px
        order: 0
      .v-list-group__header__prepend-icon
        order: 2
        +ltr()
          margin-right: 8px
        +rtl()
          margin-left: 8px
</style>
